import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosStar } from "react-icons/io";
import { MdMedicalServices } from "react-icons/md";

function Doctorcon() {
  const menu = [
    {
      name: "Dr. D Vijaya Meenakshi",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS, MD - Obstetrics & Gynaecology
        </h6>
      ),
      img: "../photo/doc1.jpg",
      rate: "",
      exper: "20",
      depat: "Obstetrics and Gynecology",
      con: "Dr. D Vijaya Meenakshi is a Gynaecologist and Obstetrician specializes in High-Risk Pregnancy, Fertility, and Pre-Conceptional Case. Her treatment includes DUB, IUCD insertion, Mirena insertion, laparoscopic tubectomy, diagnostic hysteron laparoscopy, and laparoscopy hysteroscopies. Dr. Meenakshi treats the patient afflicted with breast cancer, hormonal imbalances, and menopause symptoms. She also deals with preventative care, prenatal care, detection of sexually transmitted diseases, Pap test screening, and family planning. Dr. Meenakshi is considered to be one of the famous Obstetricians when it comes to the treatment aspect as she has profound knowledge and proficiency in Normal, C-Section, and High-Risk Pregnancy, and has performed numerous cases successfully.",
    },
    {
      name: "Dr. Ajay S S",
      dis: <h6 className="pt-2 font-medium">MS - Orthopaedics, MBBS</h6>,
      img: "../photo/doc15.jpg",
      rate: "",
      exper: "6",
      depat: "Orthopedic",
      con: "Dr. Ajay S S is a Orthopedic surgeon in Whitefield, Bangalore and has an experience of 6 years in this field. Dr. Ajay S S practices at ELV Health Care in Whitefield, Bangalore. He completed MS - Orthopaedics from Jagadguru Jayadeva Murugarajendra Medical College (JJMMC) in 2020 and MBBS from Rajiv Gandhi University of Health Sciences in 2015.",
    },
    // {
    //   name: "Dr. SACHIN PATIL ",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       M. S. GENERAL SURGERY M.B.B.S. (2009 – 2015)
    //     </h6>
    //   ),
    //   img: "../photo/doc3.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "General Surgery",
    //   con: "Dr. Sachin Patil is a highly qualified General Surgeon based in Bangalore, holding an M.B.B.S. degree earned in 2009 and subsequently achieving an M.S. in General Surgery in 2015. With over 5 years of dedicated practice, Dr. Patil has established himself as a trusted healthcare professional. His commitment to the field is reflected in his expertise and compassionate approach to General Surgery. Located in the bustling city of Bangalore, Dr. Sachin Patil serves the community with a focus on providing comprehensive and personalized healthcare, ensuring the well-being of his patients.",
    // },
    // {
    //   name: "Dr. Sainath T V",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, DCH, DNB PED, Fellowship in Neonatology, NNF
    //     </h6>
    //   ),
    //   img: "../photo/doc4.jpg",
    //   rate: "",
    //   exper: "7",
    //   depat: "Neonatologist & Pediatrician",
    //   con: "Dr. Sainath T V, a distinguished Neonatologist and Pediatrician based in Bangalore, is a highly qualified medical professional with an impressive academic background. Holding an MBBS degree, DCH (Diploma in Child Health), and DNB PED (Diplomate of National Board in Pediatrics), Dr. Sainath has further honed his skills through a Fellowship in Neonatology, accredited by the NNF (National Neonatology Forum). With over 7 years of extensive experience, he is a dedicated healthcare provider committed to the well-being of newborns and children. Dr. Sainath's expertise and commitment to neonatal care make him an invaluable asset to the medical community in Bangalore.",
    // },
    {
      name: "Dr. N.S Santhosh",
      dis: <h6 className="pt-2 font-medium">MBBS DNB-Neurology</h6>,
      img: "../photo/doc5.jpg",
      rate: "",
      exper: "21",
      depat: "Neurologist",
      con: "Dr. N.S. Santhosh, a highly regarded Neurologist based in Bangalore, boasts an illustrious medical career spanning over 21 years. Armed with an MBBS degree and specialized training in DNB-Neurology, he has become a seasoned expert in the intricacies of neurological care. Dr. Santhosh's unwavering dedication to his field is reflected not only in his extensive experience but also in his consistently high rating of 4.6/5. His wealth of knowledge and commitment to providing top-notch neurological care positions him as a trusted and compassionate healthcare professional, making a significant impact on the well-being of his patients in Bangalore.",
    },

    // {
    //   name: "Dr. Pruthviraj MO",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, DNB - OBG PGDip-Ultrasonography
    //     </h6>
    //   ),
    //   img: "../photo/doc6.jpg",
    //   rate: "",
    //   exper: "10",
    //   depat: "Obstetrics and Gynecology",
    //   con: "Dr. Pruthviraj MO, an accomplished Obstetrician and Gynecologist based in Bangalore, brings over a decade of expertise to the field of women's healthcare. With an MBBS degree, DNB in Obstetrics and Gynecology, and additional specialization in Ultrasonography through PGDip, Dr. Pruthviraj is a dedicated professional committed to the well-being of his patients. His comprehensive approach to obstetric and gynecological care, coupled with a wealth of knowledge accumulated over 10+ years, establishes him as a trusted healthcare provider. Dr. Pruthviraj's commitment to excellence is reflected in his impressive 4.6/5 rating, showcasing his unwavering dedication to providing quality and compassionate care to women in Bangalore.",
    // },
    {
      name: "Dr. Lipika Debbarma",
      dis: <h6 className="pt-2 font-medium">MBBS, DFM(RCGP,UK), PGDCD</h6>,
      img: "../photo/doc7.jpg",
      rate: "",
      exper: "5",
      depat: "Dermatologist & Cosmetologist",
      con: "Dr. Lipika Debbarma, a proficient Dermatologist and Cosmetologist based in Bangalore, combines a diverse educational background with over 5 years of dedicated professional experience. Holding an MBBS degree, she furthered her expertise with a DFM from RCGP, UK, and a PGDCD. Dr. Lipika's commitment to the field is evident in her comprehensive approach to dermatology and cosmetology, ensuring the well-being and aesthetic needs of her patients are met with precision and care. With a remarkable 4.6/5 rating, Dr. Lipika stands as a trusted healthcare professional, providing expert dermatological and cosmetic solutions to the community in Bangalore.",
    },
    {
      name: "Dr. Razia Sultana",
      dis: <h6 className="pt-2 font-medium">MBBS, DLO (ENT)</h6>,
      img: "../photo/doc8.jpg",
      rate: "",
      exper: "5",
      depat: "ENT Speialist",
      con: "Dr. Razia Sultana, an accomplished ENT Specialist in Bangalore, brings a wealth of expertise to the field of Otolaryngology. Armed with an MBBS degree and specialized training in ENT with a DLO qualification, she possesses a comprehensive understanding of ear, nose, and throat conditions. With over 5 years of dedicated practice, Dr. Razia is committed to providing exceptional care to her patients. Her proficiency is reflected not only in her qualifications but also in her impressive 4.6/5 rating, showcasing her commitment to delivering high-quality and compassionate ENT services to the community in Bangalore.",
    },
    // {
    //   name: "Dr. Vijyanta Suman",
    //   dis: <h6 className="pt-2 font-medium">BDS, MDS Prostho dentist</h6>,
    //   img: "../photo/doc9.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "Prostho dentist",
    //   con: "Dr. Vijyanta Suman, a distinguished Prosthodontist with a BDS and MDS, is a leading practitioner at Elv Health Care. With a wealth of expertise in the field of dentistry, Dr. Suman is dedicated to providing comprehensive and personalized care to her patients. Specializing in Prosthodontics, she excels in the restoration and replacement of teeth, ensuring optimal oral health and aesthetic outcomes. Known for her meticulous approach and commitment to patient well-being, Dr. Suman is a valued member of the Elv Health Care team, where her skills and compassion converge to create smiles that not only look great but also enhance overall oral function and comfort.",
    // },
    {
      name: "Dr. Anirban Bhowmik",
      dis: <h6 className="pt-2 font-medium">MBBS (PGDCC), (PGDD)</h6>,
      img: "../photo/doc10.jpg",
      rate: "",
      exper: "5",
      depat: "Cardiologist",
      con: "Dr. Anirban Bhowmik, a dedicated Cardiologist in Bangalore, brings a wealth of expertise to the field of cardiovascular health. Armed with an MBBS degree, along with specialized qualifications in PGDCC and PGDD, he possesses a comprehensive understanding of cardiac care. With over 5 years of dedicated practice, Dr. Bhowmik is committed to providing exceptional cardiovascular services to his patients. His proficiency and commitment to the well-being of his patients are evident in his impressive 4.6/5 rating. As a trusted healthcare professional, Dr. Anirban Bhowmik plays a vital role in promoting heart health and ensuring the cardiovascular wellness of the community in Bangalore.",
    },
    {
      name: "Dr. Lokesh Gowda T G",
      dis: (
        <h6 className="pt-2 font-medium">MBBS, MS, MCh (Urology) Urologist</h6>
      ),
      img: "../photo/doc11.jpg",
      rate: "",
      exper: "5",
      depat: "Urology",
      con: "Dr. Lokesh Gowda T G, a highly skilled Urologist in Bangalore, is dedicated to providing expert care in the field of urology. With a robust educational background, including an MBBS, MS, and MCh in Urology, he possesses a comprehensive understanding of urological conditions. Dr. Lokesh Gowda's commitment to excellence is evident in his 5+ years of dedicated practice, during which he has honed his skills and expertise. His proficiency in urology is reflected in his impressive 4.6/5 rating, underscoring his commitment to delivering high-quality and compassionate urological services to the community in Bangalore. Dr. Lokesh Gowda T G stands as a trusted healthcare professional, addressing the urological needs of his patients with precision and care.",
    },
    {
      name: "Ms. Jeeveetha Ragupathy",
      dis: <h6 className="pt-2 font-medium">Dietitian & Nutritionist</h6>,
      img: "../photo/doc12.jpg",
      rate: "",
      exper: "5",
      depat: "Dietitian & Nutritionist",
      con: "Ms. Jeeveetha Ragupathy, a seasoned Dietitian and Nutritionist in Bangalore, brings over 5 years of expertise to the realm of nutrition and dietary guidance. As a dedicated healthcare professional, she holds the well-being of her clients in high regard, providing personalized and comprehensive nutritional advice. Ms. Ragupathy's educational background equips her with the knowledge and skills necessary to address diverse dietary needs. Her commitment to promoting healthy lifestyles is underscored by her impressive 4.6/5 rating, reflecting the trust and satisfaction of those she has guided on their journey towards better nutrition. In Bangalore, Ms. Jeeveetha Ragupathy stands as a reliable source of nutritional support, ensuring that individuals receive expert guidance tailored to their specific health goals.",
    },
    {
      name: "Mr. Naveen Kodandaram",
      dis: <h6 className="pt-2 font-medium">Dietitian & Nutritionist</h6>,
      img: "../photo/doc13.jpg",
      rate: "",
      exper: "5",
      depat: "Dietitian & Nutritionist",
      con: "Mr. Naveen Kodandaram, a dedicated Dietitian and Nutritionist based in Bangalore, brings over 5 years of expertise to the realm of nutrition and wellness. With a commitment to promoting healthy living, he provides personalized dietary guidance to cater to individual health goals. Mr. Kodandaram's approach is rooted in a holistic understanding of nutrition, ensuring that his clients receive comprehensive and tailored advice for their well-being. His extensive experience is a testament to his dedication to the field, and his impressive 4.6/5 rating reflects the satisfaction of those he has assisted on their nutritional journeys. In Bangalore, Mr. Naveen Kodandaram stands as a trusted source of guidance for individuals seeking expert advice to achieve and maintain a balanced and healthy lifestyle.",
    },
    {
      name: "Dr. Binila",
      dis: <h6 className="pt-2 font-medium">BPT, MIAP Physiotherapist</h6>,
      img: "../photo/doc14.jpg",
      rate: "",
      exper: "20",
      depat: "Physiotherapist",
      con: "Meet Dr. Binila, a highly skilled physiotherapist at Elv Health Care, equipped with a Bachelor's degree in Physiotherapy (BPT) and recognized as a member of the Indian Association of Physiotherapists (MIAP). With a passion for promoting physical well-being, Dr. Binila specializes in providing expert rehabilitation services to patients. Her comprehensive approach and dedication to enhancing mobility and function set her apart, making her an invaluable asset to the healthcare team at Elv Health Care. Driven by a commitment to excellence, she works tirelessly to empower patients on their journey to recovery, utilizing her expertise to address a variety of musculoskeletal and neurological conditions. Dr. Binila's presence brings a vital dimension to Elv Health Care, ensuring that patients receive top-notch physiotherapy care tailored to their unique needs.",
    },
    // {
    //   name: "Dr Ajay S S",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS, MS (Orthopaedics) FJR, FSCRM Orthopaedic Surgeon
    //     </h6>
    //   ),
    //   img: "../photo/doc15.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "Orthopaedic Surgeon",
    //   con: "Dr. Ajay S S, an accomplished Orthopaedic Surgeon, holds both an MBBS and an MS in Orthopaedics, showcasing a robust educational background. With additional qualifications including FJR (Fellowship in Joint Replacement) and FSCRM (Fellowship in Stem Cell and Regenerative Medicine), he brings a comprehensive approach to musculoskeletal care. Dr. Ajay's expertise lies in orthopaedic surgery, with a focus on joint replacement and cutting-edge regenerative medicine. While specific experience and rating details are not mentioned, his commitment to advancing orthopaedic care makes him a trusted professional in the field. Dr. Ajay S S plays a pivotal role in addressing orthopaedic concerns, providing tailored solutions to enhance the mobility and well-being of his patients.",
    // },
    // {
    //   name: "Dr Pavithra",
    //   dis: <h6 className="pt-2 font-medium">MBBS</h6>,
    //   img: "../photo/doc16.jpg",
    //   rate: "",
    //   exper: "5",
    //   depat: "--",
    //   con: "Dr. Pavithra, an esteemed medical professional at MBBS Hospital and Elv Health Care, is a dedicated and compassionate physician with a commitment to providing high-quality healthcare. With a solid background in medicine, she earned her MBBS degree and has since accumulated valuable experience in diagnosing and treating a diverse range of medical conditions. Driven by a passion for patient well-being, she approaches each case with thoroughness and empathy, ensuring personalized care for every individual. Her expertise and warm demeanor make her a trusted figure in the healthcare community, embodying a holistic approach to medicine that prioritizes both physical and emotional well-being. Dr. Pavithra's presence at MBBS Hospital and Elv Health Care significantly contributes to the delivery of exceptional healthcare services.",
    // },
    {
      name: "Dr - vishnuvardhan BR",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS, MD, general medicine DM Nephrology Renal specialist
        </h6>
      ),
      img: "../photo/doc2.jpg",
      rate: "",
      exper: "5",
      depat: "Nephrologist",
      con: "Dr. Pavithra, an esteemed medical professional at MBBS Hospital and Elv Health Care, is a dedicated and compassionate physician with a commitment to providing high-quality healthcare. With a solid background in medicine, she earned her MBBS degree and has since accumulated valuable experience in diagnosing and treating a diverse range of medical conditions. Driven by a passion for patient well-being, she approaches each case with thoroughness and empathy, ensuring personalized care for every individual. Her expertise and warm demeanor make her a trusted figure in the healthcare community, embodying a holistic approach to medicine that prioritizes both physical and emotional well-being. Dr. Pavithra's presence at MBBS Hospital and Elv Health Care significantly contributes to the delivery of exceptional healthcare services.",
    },
    // {
    //   name: "Dr. Chandrika Chandran",
    //   dis: (
    //     <h6 className="pt-2 font-medium">
    //       MBBS from Calicut Medical College in 1975 and DGO from Kasturba
    //       Medical College, Manipal in 1990
    //     </h6>
    //   ),
    //   img: "../photo/02-04-24.jpg",
    //   rate: "",
    //   exper: "50",
    //   depat: "Gynecologist and Obstetrician",
    //   con: "Dr. Chandrika Chandran is a highly experienced Gynecologist and Obstetrician with an impressive track record spanning over 50 years in the field. She obtained her MBBS degree from Calicut Medical College in 1975, followed by a DGO from Kasturba Medical College, Manipal in 1990. Her specialized areas of expertise include high-risk pregnancy management, infertility treatment, managing cases with a history of bad obstetrics, performing sterilization procedures, cesarean surgeries, and hysterectomy surgeries. Dr. Chandran's extensive experience, coupled with her dedication to providing compassionate care, makes her a trusted and respected figure in the realm of women's health and maternity care.",
    // },
    {
      name: "Dr. Shalini Kumari",
      dis: (
        <h6 className="pt-2 font-medium">
          MBBS, MS( OBG ), FELLOWSHIP IN REPRODUCTIVE MEDICINE( RGUHS),MRCOG
        </h6>
      ),
      img: "../photo/dr-shalini-kumari.png",
      rate: "",
      exper: "11",
      depat: "Gynecologist and Obstetrician",
      con: "Dr. Shalini Kumari, a Senior Consultant in Reproductive Medicine with 11 years of experience, specializes in fertility treatments and gynecological procedures. She completed her Fellowship in Reproductive Medicine at Rajiv Gandhi University of Health Sciences, Bangalore, and holds an MS in OBG & GYN. A lifetime member of FOGSI and ISAR, Dr. Shalini expertly manages female sexual problems, PCOD, and offers IUI, IVF, ICSI, hysteroscopy, and laparoscopy services. Additionally, she provides comprehensive prenatal, antenatal check-ups, and addresses menstrual issues. Her extensive expertise and dedication make her a highly regarded specialist in her field.",
    },
  ];
  return (
    <>
      <section className="flex justify-center pb-8 pt-0">
        <div className="w-full">
          <div className="flex w-full mt-0">
            <div className="px-0 w-full">
              {menu.map((elem) => {
                return (
                  <>
                    <div className="w-full p-2 flex">
                      <div
                        style={{
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                        className="w-full rounded-lg bg-white"
                      >
                        <div className="flex flex-wrap  shadow-sm rounded-md p-4 relative w-full">
                          <div className="w-full flex justify-center  sm:w-1/5">
                            <div className="w-full flex md:block justify-center">
                              <img
                                className="rounded-md mb-4 w-56 sm:w-full"
                                src={elem.img}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="w-full sm:w-4/5 pl-2">
                            <h6
                              style={{ color: "#3d7cc9" }}
                              className="font-medium"
                            >
                              {elem.name}
                            </h6>
                            {elem.dis}
                            <div className="w-full mt-3 flex">
                              <div className="w-1/3 flex items-center">
                                <IoIosStar />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  4.6/5
                                </h6>
                              </div>
                              <div className="w-1/3 flex items-center">
                                <MdMedicalServices />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  {`${elem.exper}+ Years`}
                                </h6>
                              </div>
                              <div className="w-1/3 flex items-center">
                                <FaLocationDot />
                                <h6 className="text-gray-600 ml-1 md:ml-2 text-sm">
                                  Bangalore
                                </h6>
                              </div>
                            </div>
                            <button className="border-inherit border py-1 px-3 rounded-md mt-3 text-white bg-gray-400 text-sm">
                              {elem.depat}
                            </button>
                            <div className="w-full mt-4 flex justify-between">
                              <div className="w-5/12 pr-3">
                                <a
                                  href="tel:9535264855"
                                  style={{ border: "1.5px solid #3d7cc9" }}
                                  className="border-inherit border-2 text-black p-1 rounded-md w-full hidden md:flex justify-center text-sm"
                                >
                                  9535264855
                                </a>
                                <a
                                  className="border-inherit border-2 text-black p-1 rounded-md w-full flex justify-center md:hidden text-sm"
                                  href="tel:9535264855"
                                >
                                  Call Us
                                </a>
                              </div>
                              <div className="w-7/12">
                                <div className="flex w-full h-full">
                                  <a
                                    href="tel:9535264855"
                                    style={{ backgroundColor: "#3d7cc9" }}
                                    className="border-inherit border py-1 px-3 rounded-md text-white w-full text-sm h-full text-center"
                                  >
                                    Appointment
                                  </a>
                                </div>
                              </div>
                            </div>
                            <p className="mt-4">{elem.con}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Doctorcon;
